import styles from "./Language.module.css";
import { NavLink } from 'react-router-dom';
import React from "react";

const Language = () => {
  return (
    <div className={styles.container}>
      <NavLink className={styles.btn} to="/pl">PL</NavLink>
      <span>|</span>
      <NavLink className={styles.btn} to="/en">EN</NavLink>
    </div>
  );
};

export default Language;