import styles from "./Education.module.css";
import React from "react";
import { useParams } from "react-router-dom";
import Pdf from "../../images/certificat.pdf";

const Education = () => {
  const { lang } = useParams();

  return (
    <>
      <div className={styles.item}>
        <span className={styles.itemTitle}>
          {lang === "pl" ? "Wykształcenie" : "Education"}
        </span>
        <div className={styles.education}>
          <div className={styles.educationLeft}>
            <div
              className={lang === "pl" ? styles.logo1 : styles.logo1EN}
            ></div>

            <span>
              {lang === "pl"
                ? "Logistyka Międzynarodowa - Inżynier"
                : "International Logistics - B.S."}
            </span>
            <span>
              {lang === "pl"
                ? "Zarządzanie Międzynarodowe - Magister"
                : "International Management - M.Sc. IM"}
            </span>
          </div>
          <div className={styles.educationRight}>
            <div className={styles.logo2}></div>
            <span>
              {lang === "pl"
                ? "GoIT: Fullstack Developer"
                : "GoIT, Fullstack Developer"}
            </span>
            <a
              className={styles.pdf}
              href={Pdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === "pl" ? "Ceftyfikat" : "Certificat"}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Education;
