import styles from "./SoYummy.module.css";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const SoYummy = () => {
  const { lang } = useParams();

  return (
    <>
      <div className={styles.item}>
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionBoxItem}>
            <div className={styles.titleBox}>
              <a
                className={styles.link}
                href="https://main--soyummy-mj.netlify.app/"
              >
                <Logo className={styles.logo} />
              </a>
              <a
                className={styles.link}
                href="https://main--soyummy-mj.netlify.app/"
              >
                <span className={styles.titleBoxHead2green}>So</span>
                <span className={styles.titleBoxHead2}>Yummy!</span>
              </a>
            </div>
           <div className={styles.titleBox}>
              <span className={styles.appDescription}>
                {lang === "en"
                  ? " What to cook? is not only a recipe app, it is, in fact, your cookbook. You can add your own recipes to save them for the future. Add needed products to shopping list to not forget about anything while shopping!"
                  : "Co ugotować? SoYummy to nie tylko aplikacja z przepisami, to właściwie Twoja książka kucharska. Możesz dodawać własne przepisy i zapisywać je przyszłość. Dodaj potrzebne produkty do listy zakupów, aby o nich nie zapomnieć podczas zakupów!"}
              </span>
            </div> 
            <div className={styles.titleBox2}>
              <span className={styles.appInfo}>
                
                {lang === "pl"
                ? "Własny projekt, możesz znaleźć szczegóły GitHub:"
                : "Own Project, you can find it on my GitHub:"}
              </span>
              <a
                className={styles.gitLink}
                href="https://github.com/jasinskimich/So-Yummy"
              >
                GIT
              </a>
            </div>
          </div>
          <div className={styles.descriptionBoxItem}>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className={styles.mySwiper}
            >
              <SwiperSlide>
                <div className={styles.picture1}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture2}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture3}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture4}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture5}></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoYummy;
