import styles from "./ProjectsCarousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import SoYummy from "../SoYummy/SoYummy";
import Wallet from "../WalletApp/WalletApp";
import Filmoteka from "../Filmoteka/Filmoteka";


const ProjectsCarousel = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        // spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className={styles.mySwiper}
      >
        <SwiperSlide>
          <SoYummy />
        </SwiperSlide>
        <SwiperSlide>
          <Wallet />
        </SwiperSlide>
        <SwiperSlide>
          <Filmoteka />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ProjectsCarousel;
