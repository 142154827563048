import React from "react";
// import { useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
// import { useParams } from "react-router-dom";

import { Routes, Route } from "react-router-dom";

function App() {
  // const navigate = useNavigate();
  // const { lang } = useParams();
  // console.log(lang)

  // useEffect(() => {
  //   if (lang !== "en") {
  //     navigate("/pl");
  //   }
  // }, [navigate, lang]);

  return (
    <div>
      <Routes>
        <Route path="/:lang" element={<Home />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
