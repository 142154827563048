import styles from "./About.module.css";
import React from "react";
import Carousel from "../Carousel/Carousel";

const About = () => {
  return (
    <div className={styles.container}>
    
      <div className={styles.textBox}>
        <Carousel />
      </div>
    </div>
  );
};

export default About;
