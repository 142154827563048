import styles from "./Content.module.css";
import { useParams } from "react-router-dom";
import React from "react";

const Content = ( {toggleOpen, changeContent} ) => {
  const { lang } = useParams();  
  

  const handleClick = (item) => {
    changeContent(item)
    toggleOpen();
  };

  let projectsText, aboutMeText, contactInfoText;

  if (lang === "en") {
    projectsText = "Projects";
    aboutMeText = "About me";
    contactInfoText = "Contact Info";
  } else {
    projectsText = "Projekty";
    aboutMeText = "O mnie";
    contactInfoText = "Kontakt";
  }

  

  return (
    <div className={styles.container}>
      <div className={styles.item} onClick={() => handleClick("projects")}>
        <span>{projectsText}</span>
      </div>
      <div className={styles.item} onClick={() => handleClick("aboutMe")}>
        <span>{aboutMeText}</span>
      </div>
      <div className={styles.item} onClick={() => handleClick("contact")}>
        <span>{contactInfoText}</span>
      </div>
     
    </div>
  );
};

export default Content;
