import styles from "./Skills.module.css";
import React from "react";
import { useParams } from "react-router-dom";

const Skills = () => {
  const { lang } = useParams();

  return (
    <>
            <div className={styles.item}>
            <span className={styles.itemTitle}>
              {lang === "pl" ? "Umiejętności" : "Skills"}
            </span>
            <span className={styles.additional}>
              {lang === "pl"
                ? "Scrum Master, Team Lead, Zarządzanie w Projektach "
                : "Scrum Master, Team Lead, Project Management"}
            </span>
            <div className={styles.skills}>
              <div className={styles.skill2}></div>
              <div className={styles.skill3}></div>
              <div className={styles.skill4}></div>
              <div className={styles.skill6}></div>
              <div className={styles.skill7}></div>
              <div className={styles.skill1}></div>
              <div className={styles.skill5}></div>
              <div className={styles.skill8}></div>
              <div className={styles.skill9}></div>
            </div>

           
          </div>
    </>
  );
};

export default Skills;
