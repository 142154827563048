import styles from "./Projects.module.css";
import React from "react";
import ProjectsCarousel from "../ProjectsCarousel/ProjectsCarousel";

const Projects = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textBox}>
        <ProjectsCarousel />
      </div>
    </div>
  );
};

export default Projects;
