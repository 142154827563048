import React, { useRef, useEffect } from 'react';
import styles from "./Canvas.module.css";

const Canvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.style.width = window.innerWidth + 'px';
      canvas.style.height = window.innerHeight + 'px';
    }

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    let noiseData = [];
    let frame = 0;

    function createNoise() {
      const w = ctx.canvas.width,
        h = ctx.canvas.height,
        iData = ctx.createImageData(w, h),
        buffer32 = new Uint32Array(iData.data.buffer),
        len = buffer32.length;
      let i = 0;

      for(; i < len; i++)
        if (Math.random() < 0.5) buffer32[i] = 0xffffffff;

      noiseData.push(iData);
    }

    function paintNoise() {
      if (frame === 9) {
        frame = 0;
      } else {
        frame++;
      }

      ctx.putImageData(noiseData[frame], 0, 0);
    }

    function loop() {
      paintNoise();
      setTimeout(function () {
        requestAnimationFrame(loop);
      }, 1000 / 25);
    }

    for (let i = 0; i < 10; i++) {
      createNoise();
    }

    loop();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} className={styles.noise}></canvas>;
};

export default Canvas;