import styles from "./Contact.module.css";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Ig } from "../../images/ig.svg";
import { ReactComponent as Fb } from "../../images/fb.svg";
import { ReactComponent as Li } from "../../images/li.svg";
import { ReactComponent as Git } from "../../images/git.svg";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const Contact = () => {
  const { lang } = useParams();

  const FacebookIcon = () => {
    return (
      <div
        className={styles.socialIcon}
        onClick={() =>
          window.open("https://www.facebook.com/SirMichalJasinski/")
        }
      >
        <Fb />
      </div>
    );
  };

  const InstagramIcon = () => {
    return (
      <div
        className={styles.socialIcon}
        onClick={() => window.open("https://www.instagram.com/jasinski.mich")}
      >
        <Ig />
      </div>
    );
  };

  const LinkedIn = () => {
    return (
      <div
        className={styles.socialIcon}
        onClick={() => window.open("https://www.linkedin.com/in/jasinskimich/")}
      >
        <Li />
      </div>
    );
  };
  const GitHub = () => {
    return (
      <div
        className={styles.socialIcon}
        onClick={() => window.open("https://github.com/jasinskimich")}
      >
        <Git />
      </div>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className={styles.container}>
      <ToastContainer />
      <div className={styles.title}>
      <span>{lang === 'pl' ? 'Kontakt' : 'Contact'}</span>
      </div>
      <div className={styles.socials}>
        <FacebookIcon />
        <InstagramIcon />
        <LinkedIn />
        <GitHub />
      </div>
      <div className={styles.direct}>
        <CopyToClipboard text="jasinskimichal.1996@gmail.com" onCopy={() => handleCopy("jasinskimichal.1996@gmail.com")}>
          <span className={styles.info}>jasinskimichal.1996@gmail.com</span>
        </CopyToClipboard>
        <CopyToClipboard text="+48 694 616 480" onCopy={() => handleCopy("+48 694 616 480")}>
          <span className={styles.info}>+48 694 616 480</span>
        </CopyToClipboard>
        <span>{lang === 'pl' ? 'Kraków' : 'Cracow'}</span>
      </div>
    </div>
  );
};

export default Contact;