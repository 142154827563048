import styles from "./Experience.module.css";
import React from "react";
import { useParams } from "react-router-dom";

const Experience = () => {
  const { lang } = useParams();

  return (
    <>
        <div className={styles.item}>
            <span className={styles.itemTitle}>
              {lang === "pl" ? "Doświadczenie" : "Experience"}
            </span>
            <div className={styles.experience}>
              <div className={styles.experienceBox}>
                <div
                  className={styles.job1}
                ></div>

                <span>
                  {lang === "pl"
                    ? "Alexander Mann Solutions sp. Z O.O."
                    : "Alexander Mann Solutions sp. Z O.O."}
                </span>
                <span>
                  {lang === "pl"
                    ? "Starszy Specjalista do Spraw Rekruatacji"
                    : "Senior Recruitment Coordinator"}
                </span>
              </div>
              <div className={styles.experienceBox}>
                <div className={styles.job2}></div>
                <span>
                  {lang === "pl" ? "Surowa Sp. Z O.O." : "Surowa Sp. Z O.O."}
                </span>
                <span>
                  {lang === "pl"
                    ? "Specialista od Marketingu "
                    : "Marketing Specialist"}
                </span>
                
              </div>
            </div>
         
          </div>
    </>
  );
};

export default Experience;
