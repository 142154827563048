import styles from "./Carousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import Experience from "../Experience/Experience";
import Skills from "../Skills/Skills";
import Education from "../Education/Education";

const Carousel = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        // spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className={styles.mySwiper}
      >
        <SwiperSlide className={styles.swiperSlide}>
          <Education />
        </SwiperSlide>
        <SwiperSlide>
          <Experience />
        </SwiperSlide>
        <SwiperSlide>
          <Skills />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
