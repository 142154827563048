import styles from "./Filmoteka.module.css";
import React from "react";
import { useParams } from "react-router-dom";
// import { ReactComponent as Logo } from "";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../../images/film.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Filmoteka = () => {
  const { lang } = useParams();

  return (
    <>
      <div className={styles.item}>
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionBoxItem}>
            <div className={styles.titleBox}>
              <a
                className={styles.link}
                href="https://jasinskimich.github.io/project-group-6/"
              >
                <img src={logo} alt="film" className={styles.linkImage} />
              </a>
              <a
                className={styles.link}
                href="https://jasinskimich.github.io/project-group-6/"
              >
                <span className={styles.titleBoxHead2}>Filmoteka</span>
              </a>
            </div>
            <div className={styles.titleBox}>
              <span className={styles.appDescription}>
                {lang === "en"
                  ? " What would you like to watch today? Filmoteka app is the best source of films, you can search for new popular titles, see film description, rating, poster etc. If you want to save a title, just add it to your personal list! Share with friends! "
                  : "Co chciałbyś dzisiaj obejrzeć? Aplikacja Filmoteka to najlepsze źródło filmów, możesz wyszukiwać nowe popularne tytuły, przeglądać opis filmu, ocenę, plakat itp. Jeśli chcesz zapisać tytuł, po prostu dodaj go do swojej osobistej listy! Podziel się z przyjaciółmi!"}
              </span>
            </div>
            <div className={styles.titleBox2}>
              <span className={styles.appInfo}>
                {lang === "pl"
                  ? "Projekt Grupowy, Rola: Team Leader + Scrum Master"
                  : "Group Project, Role: Team Leader + Scrum Master"}
              </span>
              <span className={styles.appInfo}>
                {lang === "pl"
                  ? "Możesz znaleźć szczegóły GitHub:"
                  : "You can find it on my GitHub:"}
              </span>
              <a
                className={styles.gitLink}
                href="https://github.com/jasinskimich/project-group-6"
              >
                GIT
              </a>
            </div>
          </div>
          <div className={styles.descriptionBoxItem}>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className={styles.mySwiper}
            >
              <SwiperSlide>
                <div className={styles.picture1}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture2}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture3}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture4}></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filmoteka;
