import styles from "./Home.module.css";
import Canvas from "../../components/Canvas/Canvas";
import Content from "../../components/Content/Content";
import Language from "../../components/Language/Language";
import Projects from "../../components/Projects/Projects";
import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";
import { ReactComponent as Arrow } from "../../images/arrow.svg";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import React, { useEffect } from "react";
import React, { useEffect, useState } from "react";

function Home() {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (typeof lang === "undefined") {
      navigate("/pl");
    }
  }, [navigate, lang]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const changeContent = (item) => {
    setContent(item);
  };

  let contentToRender;

  switch (content) {
    case "projects":
      contentToRender = <Projects />;
      break;
    case "aboutMe":
      contentToRender = <About />;
      break;
    case "contact":
      contentToRender = <Contact />;
      break;
    default:
      contentToRender = null;
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={styles.app}>
      <Canvas />
      <div className={styles.appHeader}>
        <p className={styles.header}>Michał Jasiński</p>

        {windowWidth < 400 ? (
          <div className={isOpen ? styles.hidden : styles.showed}>
            <p className={styles.note}>Full Stack Developer</p>
            <p className={styles.note}>Marketing Specialist</p>
            <p className={styles.note}>Recruitment Project Coordinator</p>
            <Content toggleOpen={toggleOpen} changeContent={changeContent} />
            <Language />
          </div>
        ) : (
          <>
            <p className={styles.note}>Full Stack Developer</p>
            <p className={styles.note}>Marketing Specialist</p>
            <p className={styles.note}>Recruitment Project Coordinator</p>
            <div className={isOpen ? styles.hidden : styles.showed}>
              <Content toggleOpen={toggleOpen} changeContent={changeContent} />
              <Language />
            </div>
          </>
        )}
      </div>
      <div className={isOpen ? styles.open : styles.closed}>
        {contentToRender}
        <div className={styles.btnClose} onClick={toggleOpen}>
          <Arrow />
        </div>
      </div>
    </div>
  );
}

export default Home;
