import styles from "./WalletApp.module.css";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/Wallet.svg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const WalletApp = () => {
  const { lang } = useParams();

  return (
    <>
      <div className={styles.item}>
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionBoxItem}>
            <div className={styles.titleBox}>
              <a
                className={styles.link}
                href="https://main--avengers-wallet-app.netlify.app/"
              >
                <Logo className={styles.logo} />
              </a>
              <a
                className={styles.link}
                href="https://main--avengers-wallet-app.netlify.app/"
              >
                <span className={styles.titleBoxHead2}>Wallet App</span>
              </a>
            </div>
            <div className={styles.titleBox}>
              <span className={styles.appDescription}>
                {lang === "pl"
                  ? "Chcesz mieć wgląd we własne wydatki? Mamy dla ciebie doskonałą aplikacja, w której możesz przechowywać wszystkie swoje dochody i wydatki. Tworz wykresy i kontroluj swoje wydatki. Możesz także zobaczyć wartość najbardziej znanych waluty i nie tylko! Odwiedź naszą aplikację!"
                  : "Ready to have insight in own spendings? Here we have excellent app where you can stash all your income and expences. Create graphs and controle your spendings. You can also see value of most known currencies and more! Visit our App!"}
              </span>
            </div>
            <div className={styles.titleBoxBottom}>
              <span className={styles.appInfo}>
                {lang === "pl"
                  ? "Projekt Grupowy, Rola: Team Leader + Scrum Master"
                  : "Group Project, Role: Team Leader + Scrum Master"}
              </span>
              <div >
                <span className={styles.appInfo}>
                  {lang === "pl"
                    ? "GitHub:"
                    : "GitHub:"}
                </span>
                <a
                  className={styles.gitLink}
                  href="https://github.com/jasinskimich/avengers-wallet-app"
                >
                  GIT
                </a>
              </div>
            </div>
          </div>
          <div className={styles.descriptionBoxItem}>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className={styles.mySwiper}
            >
              <SwiperSlide>
                <div className={styles.picture1}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture2}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture3}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.picture4}></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletApp;
